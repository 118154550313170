<script lang="ts" setup>
import type { ResponsiveImageType } from "vue-datocms";
import type { CallToActionSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

defineProps<{ data: CallToActionSectionRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <div class="full-bleed 2xl:no-bleed relative overflow-hidden">
    <DatocmsImage
      v-if="data.backgroundImage" :data="data.backgroundImage.responsiveImage as ResponsiveImageType"
      :lazy-load="false" object-fit="cover" object-position="right" class="!absolute size-full sm:h-[150%] lg:h-full 2xl:rounded-2xl"
    />
    <Vector class="vector--background top-xl lg:right-xl absolute right-[-10%] h-auto w-[200px] lg:-bottom-1/4 lg:top-auto lg:w-[270px] xl:w-[340px]" />
    <div class="mx-xl py-2xl md:py-2xl lg:py-3xl xl:mr-2xl xl:py-4xl 2xl:py-5xl relative flex h-full w-fit flex-col justify-center md:mx-auto lg:ml-[38%] xl:ml-[45%]">
      <span class="mb-2xs text-th-neutral-400 text-xs font-normal uppercase tracking-widest xl:text-sm">{{ data.superTitle }}</span>
      <h3 class="mb-3">
        {{ data.title }}
      </h3>
      <div class="markdown-content mb-2xl text-md leading-7 xl:text-lg xl:leading-7" v-html="data.text" />
      <UButton
        v-if="data.pageLink.length"
        variant="solid" color="black" size="sm" class="w-fit font-normal"
        :to="localePath(`/${data.pageLink.at(0)?.pageLink.slug}`)"
      >
        {{ data.pageLink.at(0)?.text }}
      </UButton>
    </div>
  </div>
</template>

<style scoped>
.vector--background {
  --esc-vector-fill-color: black;
  --esc-vector-mix-blend-mode: soft-light;
  --esc-vector-opacity: 0.3;
}
</style>
