<script lang="ts" setup>
import type { AllTrendsSectionRecord, ImageFileField, TrendsInspirationTagRecord } from "~~/server/graphql/datocms/generated";
import { useUrlSearchParams } from "@vueuse/core";

defineProps<{ data: AllTrendsSectionRecord }>();

const localePath = useLocalePath();
const { locale, t } = useI18n();
const route = useRoute();

const pageSize = 18;
// maybe use https://vueschool.io/articles/vuejs-tutorials/zod-and-query-string-variables-in-nuxt for query string validation?
const page = ref(route.query.page ? Number.parseInt(route.query.page.toString()) : 1);
const tags = ref(route.query.tags ? route.query.tags.toString().split(",") : []);

const loadingIndicator = useLoadingIndicator();

const { data: trendsData, pending: trendsPending } = await useFetch("/api/cms/trends", {
  query: { pageSize: pageSize, page: page, tags: tags, locale: locale },
  deep: true,
  onRequest: function () {
    loadingIndicator.start();
  },
  onResponse: function () {
    loadingIndicator.finish();
  },
});

const { data: trendsTags } = await useFetch("/api/cms/trends/tags", {
  query: { locale: locale },
});

function isInTagFilter(tagId: string) {
  return tags.value.includes(tagId);
}

function toggleTagFilter(tagId: string) {
  if (isInTagFilter(tagId)) {
    tags.value.splice(tags.value.indexOf(tagId), 1);
  }
  else {
    tags.value.push(tagId);
  }
}

watch(trendsPending, () => {
  if (trendsPending.value) {
    const element = document.getElementById("trends-list");
    element?.scrollIntoView({ behavior: "smooth" });

    const params = useUrlSearchParams("history");
    params.page = page?.value.toString();
    params.tags = tags?.value.join(",");
  }
});

// not required, but cleaner
const itemCount = computed(() => {
  return trendsData.value?._allTrendsInspirationsMeta?.count ?? 0;
});
</script>

<template>
  <div id="trends-list" class="scroll-mt-8">
    <div class="mb-3xl flex items-center">
      <span class="font-semibold">{{ t('filter.category') }}</span>
      <div class="mx-lg gap-sm flex flex-wrap items-center">
        <div v-for="trendsTag in trendsTags" :key="trendsTag.id">
          <UBadge
            v-if="!isInTagFilter(trendsTag.id)"
            variant="solid" color="primary" size="xs" class="cursor-pointer"
            @click="toggleTagFilter(trendsTag.id)"
          >
            {{ trendsTag.tag.toUpperCase() }}
          </UBadge>
          <UBadge
            v-if="isInTagFilter(trendsTag.id)"
            variant="solid" color="primary" size="xs"
            :ui="{ strategy: 'override', variant: { solid: 'bg-primary text-white' } }" class="cursor-pointer"
            @click="toggleTagFilter(trendsTag.id)"
          >
            {{ trendsTag.tag.toUpperCase() }}
          </UBadge>
        </div>
      </div>
    </div>

    <div v-show="itemCount > 0" class="mb-lg gap-lg md:mb-3xl md:gap-md grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      <article v-for="trend in trendsData?.allTrendsInspirations" :key="trend.id">
        <NuxtLink :to="localePath({ name: 'trends-slug', params: { slug: trend.slug } })">
          <TeaserCard
            :page-id="trend.id"
            :image="trend.teaserImage as ImageFileField"
            :title="trend.title"
            :text="trend.teaserText"
            :tags="trend.tags as TrendsInspirationTagRecord[]"
          />
        </NuxtLink>
      </article>
    </div>

    <div v-show="itemCount > pageSize" class="flex justify-center">
      <UPagination v-model="page" :page-count="pageSize" :total="itemCount">
        <template #prev="{ onClick }">
          <UTooltip text="Previous page">
            <UButton
              icon="i-ph-arrow-circle-left"
              class="bg-th-neutral-50 text-th-neutral-500 size-10 rounded-md rtl:[&_span:first-child]:rotate-180"
              @click="onClick"
            />
          </UTooltip>
        </template>

        <template #next="{ onClick }">
          <UTooltip text="Next page">
            <UButton
              icon="i-ph-arrow-circle-right"
              class="bg-th-neutral-50 text-th-neutral-500 size-10 rounded-md rtl:[&_span:last-child]:rotate-180"
              @click="onClick"
            />
          </UTooltip>
        </template>
      </UPagination>
    </div>
  </div>
</template>

<style scoped>

</style>
