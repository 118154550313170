<script lang="ts" setup>
import type { AllEventsSectionRecord, EventRecord } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ data: AllEventsSectionRecord }>();

const { locale } = useI18n();
const currentDateAsString = (new Date()).toLocaleDateString(locale.value, { day: "2-digit", month: "2-digit", year: "numeric" });

const { data: eventsData } = await useFetch("/api/cms/events", {
  query: { pageSize: 100, page: 1, endDate: currentDateAsString, locale: locale },
  deep: true,
});

// not required, but cleaner
const itemCount = computed(() => {
  return eventsData.value?._allEventsMeta?.count ?? 0;
});
</script>

<template>
  <div id="events-list" class="scroll-mt-8">
    <div class="flex flex-col items-center justify-center">
      <h2 class="mb-3xl">
        {{ props.data.title }}
      </h2>

      <div class="mb-3xl w-full xl:w-4/5">
        <div v-show="itemCount > 0" class="gap-lg md:gap-md grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <EventCard v-for="event in eventsData?.allEvents" :key="event.id" :event="event as EventRecord" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
