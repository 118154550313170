<script setup lang="ts">
import type { ResponsiveImageType } from "vue-datocms";
import type { ImageFileField, StoreRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

defineProps<{ store: StoreRecord }>();

const localePath = useLocalePath();
const { t } = useI18n();
</script>

<template>
  <div>
    <NuxtLink :to="localePath({ name: 'stores-slug', params: { slug: store.slug } })">
      <DatocmsImage
        v-if="store.teaserImage as ImageFileField" :data="store.teaserImage.responsiveImage as ResponsiveImageType" object-fit="cover"
        class="esc-store-image rounded-b-2xl"
      />
    </NuxtLink>

    <div class="p-lg">
      <div class="mb-lg">
        <h5 class="mb-md">
          {{ store.name }}
        </h5>
        <div class="mb-md" v-html="store.address" />
        <div v-if="store.optPhoneNumber">
          {{ store.optPhoneNumber }}
        </div>
        <div v-if="store.optFaxNumber" class="mb-md">
          {{ store.optFaxNumber }}
        </div>
        <a v-if="store.optEmailAddress" :href="`mailto:${store.optEmailAddress}`" class="text-primary underline">
          {{ store.optEmailAddress }}
        </a>
      </div>

      <hr class="mb-lg border-th-neutral-100">

      <CollapsibleContent class="mb-lg" :label="t('store.label.opening-hours.show')">
        <div class="mt-md ms-5">
          <StoreOpeningHours :opening-hours="store.openingHours" />
        </div>
      </CollapsibleContent>

      <UButton variant="solid" color="black" size="sm" :to="localePath({ name: 'stores-slug', params: { slug: store.slug } })">
        {{ store.teaserLinkText }}
      </UButton>
    </div>
  </div>
</template>
