<script lang="ts" setup>
import type { ToMetaTagsType } from "vue-datocms";
import {
  SectionsAllEvents,
  SectionsAllNews,
  SectionsAllStores,
  SectionsAllTrends,
  SectionsBrands,
  SectionsCallToAction,
  SectionsContent,
  SectionsFallback,
  SectionsFaq,
  SectionsForm,
  SectionsHeader,
  SectionsHomepageHero,
  SectionsLatestTrends,
  SectionsMagazine,
  SectionsNewsletterSubscription,
  SectionsProductGroupDetail,
  SectionsProductGroupOverview,
  SectionsSplit,
  SectionsSteps,
  SectionsTestimonals,
  SectionsUpcomingEvents,
  SectionsUsp,
} from "#components";
import { toHead } from "vue-datocms";

const { locale } = useI18n();
const { params } = useRoute();
const slug = params.slug || "home";

const { data } = await useFetch("/api/cms/page", {
  query: { slug: slug, locale: locale },
});

if (!data.value) {
  throw create404Error();
}

useDatoSlugsToI18n(data.value._allSlugLocales!);

useHead(() => {
  if (!data.value) {
    return {};
  }

  return toHead(data.value._seoMetaTags as ToMetaTagsType);
});

function GetComponentName(typename: string) {
  switch (typename) {
    case "AllEventsSectionRecord":
      return SectionsAllEvents;
    case "AllNewsSectionRecord":
      return SectionsAllNews;
    case "AllStoresSectionRecord":
      return SectionsAllStores;
    case "AllTrendsSectionRecord":
      return SectionsAllTrends;
    case "BrandsSectionRecord":
      return SectionsBrands;
    case "CallToActionSectionRecord":
      return SectionsCallToAction;
    case "FormSectionRecord":
      return SectionsForm;
    case "ContentSectionRecord":
      return SectionsContent;
    case "FaqSectionRecord":
      return SectionsFaq;
    case "HeaderSectionRecord":
      return SectionsHeader;
    case "HomepageHeroSectionRecord":
      return SectionsHomepageHero;
    case "LatestTrendsSectionRecord":
      return SectionsLatestTrends;
    case "MagazineSectionRecord":
      return SectionsMagazine;
    case "NewsletterSubscriptionSectionRecord":
      return SectionsNewsletterSubscription;
    case "ProductGroupDetailSectionRecord":
      return SectionsProductGroupDetail;
    case "ProductGroupOverviewSectionRecord":
      return SectionsProductGroupOverview;
    case "SplitSectionRecord":
      return SectionsSplit;
    case "StepsSectionRecord":
      return SectionsSteps;
    case "TestimonialsSectionRecord":
      return SectionsTestimonals;
    case "UpcomingEventsSectionRecord":
      return SectionsUpcomingEvents;
    case "UspSectionRecord":
      return SectionsUsp;
    default:
      return SectionsFallback;
  }
}
</script>

<template>
  <div class="mt-3xl">
    <Breadcrumbs class="mb-sm" :page="data" />

    <div v-if="data">
      <section v-for="section in data.sections" :key="(section as any).id" class="mb-3xl lg:mb-5xl">
        <!-- {{ section }} -->
        <div>
          <component :is="GetComponentName(section.__typename)" :data="section" :page-title="data.title" :current-page-id="data.id" />
        </div>
      </section>
    </div>
  </div>
</template>

<style>

</style>
