<script lang="ts" setup>
import type { StoreRecord } from "~~/server/graphql/datocms/generated";

const { locale } = useI18n();
const { data: storesData } = await useFetch("/api/cms/stores", {
  query: { locale: locale },
  deep: true,
});

// not required, but cleaner
const itemCount = computed(() => {
  return storesData.value?._allStoresMeta?.count ?? 0;
});
</script>

<template>
  <div id="stores-list">
    <div v-show="itemCount > 0" class="mb-lg gap-lg md:mb-3xl md:gap-md grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      <StoreCard v-for="store in storesData?.allStores" :key="store.id" :store="store as StoreRecord" />
    </div>
  </div>
</template>

<style scoped>

</style>
