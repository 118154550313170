<script lang="ts" setup>
import type { ResponsiveImageType } from "vue-datocms";
import type { CustomLinkRecord, HomepageHeroSectionRecord, ImageLinkRecord, PageLinkRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const props = defineProps<{ data: HomepageHeroSectionRecord }>();

const localePath = useLocalePath();

const heroImageLink = computed(() => {
  if (props.data.pageLink.at(0)?._modelApiKey === "custom_link") {
    const customLink = props.data.pageLink.at(0) as CustomLinkRecord;

    return {
      id: customLink.id,
      label: customLink.text,
      to: customLink.customLink,
    };
  }

  if (props.data.pageLink.at(0)?._modelApiKey === "page_link") {
    const pageLink = props.data.pageLink.at(0) as PageLinkRecord;

    return {
      id: pageLink.id,
      label: pageLink.text,
      to: getUrl(pageLink.pageLink!.slug, localePath),
    };
  }

  return null;
});

function getImageLinkUrl(imageLink: ImageLinkRecord) {
  if (imageLink.optPageLink) {
    return getUrl(imageLink.optPageLink!.slug, localePath);
  }

  if (imageLink.optCustomLink) {
    return getUrl(imageLink.optCustomLink, localePath);
  }

  return "/";
}
</script>

<template>
  <div>
    <div class="mb-xs gap-xs flex flex-wrap lg:flex-nowrap">
      <div class="order-2 flex max-w-full items-center lg:order-1 lg:max-w-md xl:max-w-lg">
        <div class="p-md lg:p-xl">
          <h2 class="mb-md lg:mb-lg">
            {{ data.heroTitle }}
          </h2>

          <div class="mb-md lg:mb-lg" v-html="data.heroText" />

          <UButton
            v-if="heroImageLink"
            variant="outline" color="primary" size="md" class="mb-lg lg:mb-0"
            :to="heroImageLink.to"
            :target="getTarget(heroImageLink.to)"
          >
            {{ heroImageLink.label }}
          </UButton>
        </div>
      </div>

      <div class="relative order-1 w-full lg:order-2">
        <NuxtLinkLocale v-if="heroImageLink" :to="heroImageLink.to" :target="getTarget(heroImageLink.to)">
          <DatocmsImage
            v-if="data.heroImage"
            :data="data.heroImage.responsiveImage as ResponsiveImageType"
            :lazy-load="false"
            object-fit="cover" class="rounded-lg"
          />
        </NuxtLinkLocale>

        <DatocmsImage
          v-if="!heroImageLink && data.heroImage" :data="data.heroImage.responsiveImage as ResponsiveImageType"
          :lazy-load="false"
          object-fit="cover" class="rounded-lg"
        />

        <VectorWithStroke class="vector--main-image absolute -left-3 -top-3 h-[1.95rem] w-8 rotate-[80deg] xl:h-[4.5rem] xl:w-[4.625rem]" />
      </div>
    </div>

    <div class="gap-xs grid grid-cols-1 lg:grid-cols-2">
      <div v-for="imageLink in data.imageLinks" :key="imageLink.id" class="relative">
        <NuxtLinkLocale :to="getImageLinkUrl(imageLink)">
          <DatocmsImage
            v-if="imageLink.image" :data="imageLink.image.responsiveImage as ResponsiveImageType"
            :lazy-load="false"
            object-fit="cover" class="rounded-lg"
          />
          <div class="absolute inset-0 rounded-lg bg-gradient-to-b from-transparent to-[#3C2828]">
            <div class="inset-x-md bottom-md absolute flex items-center justify-between">
              <h4 class="text-white">
                {{ imageLink.title }}
              </h4>

              <img src="/icons/arrow-circle-top-right-white.svg" alt="">
            </div>
          </div>
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<style scoped>
.vector--main-image {
  --esc-vector-fill-color: #c81414;
  --esc-vector-opacity: 1;
  --esc-vector-mix-blend-mode: normal;
}
</style>
